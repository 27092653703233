<template>
  <Layout>
    <BHeader>
      <!-- <div style="display:flex;align-items:center;margin:10px">
        <span
          style="font-size: 16px;
    color: #606266;"
        >签到设置：</span>
        <el-radio
          v-model="radio"
          label="1"
        >
          开启
        </el-radio>
        <el-radio
          v-model="radio"
          label="2"
        >
          关闭
        </el-radio>
      </div> -->
      <!-- <el-form
        ref="form"
        :inline="true"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item prop="keyWord">
          <el-input
            v-model="formData.keyWord"
            clearable
            placeholder="标签名称"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form> -->
      <template #right>
        <el-button
          type="prrmary"
          :disabled="radio === '2'"
          @click="handleCreateTag"
        >
          新增商品
        </el-button>
      </template>
    </BHeader>
    <div
      style="font-size: 16px;
    color: #606266;margin:10px"
    >
      商品列表：
    </div>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%;margin-top:10px"
    >
      <el-table-column
        label="奖品"
        prop="prizeName"
      />
      <el-table-column
        label="数量"
        prop="prizeNumber"
      />
      <el-table-column
        label="商品类型"
      >
        <template v-slot="{row}">
          <span v-if="row.prizeType===1">积分</span>
          <span v-if="row.prizeType===2">时长</span>
        </template>
      </el-table-column>
      <el-table-column
        label="奖品图片"
        min-width="200px"
        align="center"
      >
        <template v-slot="{row}">
          <el-image
            style="width: 160px;height:160px"
            class="game-cover"
            fit="contain"
            :src="row.prizeImg"
            lazy
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
      >
        <template #default="{row}">
          <BTextButton
            :disabled="radio === '2'"
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :disabled="radio === '2'"
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />

    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="showDialogLoading"
        :model="subForm"
        :rules="subFormRules"
        label-width="100px"
      >
        <el-form-item
          label="奖品名称"
          prop="prizeName"
        >
          <el-input
            v-model="subForm.prizeName"
            style="width: 200px;"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="输入数量"
          prop="prizeNumber"
        >
          <el-input
            v-model="subForm.prizeNumber"
            style="width: 200px;"
            show-word-limit
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="商品类型"
          prop="prizeType"
        >
          <el-select
            v-model="subForm.prizeType"
            style="width:200px"
            placeholder="请选择商品类型"
          >
            <el-option
              v-for="item in goodsList"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            />
          </el-select>
          <!-- <el-input
            v-model="subForm.prizeCompany"
            style="width: 200px;"
            show-word-limit
          /> -->
        </el-form-item>
        <el-form-item
          label="上传图片："
          prop="prizeImg"
        >
          <UploadImage
            :limit="1"
            :file-list.sync="swiperList"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
      </span>
    </el-dialog>
    <div style="margin-top: 20px;">
      <el-radio
        v-model="inradio"
        :disabled="radio === '2'"
        label="1"
        border
      >
        下期设置
      </el-radio>
      <el-radio
        v-model="inradio"
        :disabled="radio === '2'"
        label="2"
        border
      >
        本期内容
      </el-radio>
      <div
        v-show="isCurrent"
        style="float: right;margin-right: 120px;color:#909399;font-size:15px;margin-top:8px"
      >
        开始时间到结束时间： {{ whenDataTime }}
      </div>
    </div>
    <div
      v-if="inradio=== '1'"
      style="margin:20px"
    >
      <el-radio-group
        v-model="nextradio"
        :disabled="radio === '2'"
      >
        <el-radio
          label="1"
        >
          7天
        </el-radio>
        <el-radio
          label="2"
        >
          15天
        </el-radio>
        <el-radio
          label="3"
        >
          30天
        </el-radio>
      </el-radio-group>
    </div>
    <div
      v-if="contentlist.length>0 &&contentlist!==null"
      class="formcontent"
    >
      <div
        v-for="(t,i) of contentlist"
        :key="i"
        class="div"
      >
        <div
          v-if="isCurrent"
          style="width: 200px;text-align: center;padding-top: 5px;font-size: 14px;"
        >
          {{ t.prize.prizeName }}
        </div>
        <el-select
          v-else
          v-model="t.info.prizeId"
          style="width:200px"
          placeholder="请选择商品"
          :disabled="isCurrent"
        >
          <el-option
            v-for="item in tagTypeList"
            :key="item.id"
            :label="item.prizeName"
            :value="item.id"
          />
        </el-select>
        <el-image
          v-if="!isCurrent"
          class="game-cover"
          fit="contain"
          style="width: 160px;height:160px"
          :src="getprizeImg(t.info.prizeId)"
          lazy
        />
        <el-image
          v-else
          style="width: 160px;height:160px"
          class="game-cover"
          fit="contain"
          :src="t.prize.prizeImg"
          lazy
        />
        <!-- <el-image
          v-else
          class="game-cover"
          fit="contain"
          src=""
          lazy
        /> -->
        <div class="content">
          第{{ t.info.whatDay }}天
        </div>
      </div>
    </div>
    <div
      style="height: 100px;
    display: flex;
    align-items: center;
    justify-content: right;"
    >
      <el-button
        v-show="!isCurrent"
        type="primary"
        style="margin-right:220px"
        @click="Confirmrelease"
      >
        确认发布
      </el-button>
    </div>
  </Layout>
</template>

<script>
import {
  getDailyCheckinPrize,
  saveDailyCheckinPrize,
  getDailyCheckinPeriod,
  getDailyCheckinConfig,
  dailyCheckinType,
  saveDailyCheckinConfig
  // deleteGameTag,
  // queryGameTagdetail,
  // // tagNameValidateCheck,
  // createNewTag,
  // updateGameTag
} from '@/api/checksetting.js'

export default {
  data () {
    const swiperValidator = (rule, value, callback) => {
      if (this.swiperList.length) {
        return callback()
      } else {
        callback(new Error('cover is required'))
      }
    }
    return {
      whenDataTime: '', // 驼峰驼峰！！！！！！！！！！！！！！！！！！！！！！！！！！
      contentlist: [],
      contentid: '',
      contentlistnum: 7,
      contentdays: '',
      ouindays: 7,
      radio: '1',
      inradio: '1',
      nextradio: '1',
      swiperList: [],
      tagTypeList: [],
      descTypeMap: {},
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      goodsList: [],
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      isCurrent: false,
      showDialog: false,
      dialogTile: '',
      subForm: {},
      subFormRules: {
        prizeImg: [{ required: true, validator: swiperValidator }],
        prizeName: [
          { required: true, message: '商品名称必填' }
          // { validator: repeatNameValidator }
        ],
        prizeType: [
          { required: true, message: '商品名称必选' }
          // { validator: repeatNameValidator }
        ],
        prizeNumber: [
          { required: true, message: '商品数量必填' }
          // { validator: repeatNameValidator }
        ]
      },
      showDialogLoading: false,
      updateLoading: false,
      periodInfo: {}, // 当前选项卡奖项信息
      dataMap: { 1: 7, 2: 15, 3: 30 } // 切换日期天数map, 7, 15, 30天
    }
  },
  computed: {
    descTypeList () {
      return this.descTypeMap[this.subForm.parentGroupId] || []
    }
  },
  watch: {
    inradio (oldVal) {
      this.contentdays = '' // 这是干嘛的？ 不是数字吗
      if (oldVal === '1') {
        this.isCurrent = false
      } else {
        this.isCurrent = true
      }
      this.getsetList()
    },
    nextradio (oldVal) {
      this.ouindays = this.dataMap[oldVal]
      getDailyCheckinConfig({ periodId: this.contentid })
        .then(res => {
          if (res.code === 200) {
            this.contentlist = res.data.list ? res.data.list : [] // 处理后端返回null，统一为数组格式
          }
        })
        .finally(() => {
          this.listInfo.loading = false
          if (this.contentlist.length === 0 || this.periodInfo.days !== this.ouindays) { // 空白卡片，用于新增奖项配置
            this.buildDefaultConfig(this.ouindays)
          }
        })
    }
  },
  created () {
    this.handleSearch()
    this.getsetList()
    dailyCheckinType({})
      .then(res => {
        if (res.code === 200) {
          this.goodsList = res.data || []
        }
      })
  },
  methods: {
    getprizeImg (id) {
      if (id === 0) { return '' }
      return this.tagTypeList.filter((item) => {
        return item.id === id
      })[0]?.prizeImg || ''
    },
    Confirmrelease () {
      const list = []
      let ishavetext = true
      for (var t = 0; t < this.contentlist.length; t++) {
        list.push(this.contentlist[t].info)
      }
      const obj = { periodId: this.contentid, days: this.ouindays, list: list }
      for (var i = 0; i < list.length; i++) {
        if (!list[i].prizeId) {
          ishavetext = false
          this.$message.success('请分配完对应商品')
          return
        }
      }
      if (ishavetext) {
        saveDailyCheckinConfig(obj)
          .then(res => {
            if (res.code === 200) {
              this.$message.success('发布成功')
              this.getsetList()
            }
          })
          .finally(() => {
            this.listInfo.loading = false
          })
      }
    },
    inputhandle ({ target }) {
      this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '')
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    // isCurrent
    getsetList () {
      this.listInfo.loading = true
      getDailyCheckinPeriod({ isCurrent: this.isCurrent })
        .then(res => {
          this.periodInfo = res.data // 本期、下期选项卡奖项信息

          if (res.code === 200) {
            this.contentid = res.data.id
            if (res.data.days !== 0) { // 名字好抽象啊。。。。。
              this.contentdays = res.data.days
              this.ouindays = res.data.days
            }
            if (res.data.days === 7) { // 建议直接用天数map, 直接映射 {7:7, 15: 15 ....}，一行代码搞定
              this.nextradio = '1'
            } else if (res.data.days === 15) {
              this.nextradio = '2'
            } else if (res.data.days === 30) {
              this.nextradio = '3'
            }
            this.whenDataTime = res.data.startTime + '-' + res.data.endTime
            // res.data.days
            getDailyCheckinConfig({ periodId: res.data.id })
              .then(res => {
                if (res.code === 200) {
                  if (res.data.list === null) {
                    this.buildDefaultConfig(this.ouindays)
                  } else {
                    // this.whenDataTime = res.data.list[0].info.date + '-' + res.data.list[res.data.list.length - 1].info.date
                    this.contentlist = res.data.list
                  }
                }
              })
              .finally(() => {
                this.listInfo.loading = false
              })
            // this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    queryDataList () {
      this.listInfo.loading = true
      getDailyCheckinPrize(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.tagTypeList = res.data.list || []
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },

    handleCreateTag () {
      this.dialogTile = '新增商品'
      this.showDialog = true
      this.swiperList = []
    },
    handleEditItem (row) {
      this.dialogTile = '编辑商品'
      this.showDialog = true
      this.showDialogLoading = true

      getDailyCheckinPrize({
        id: row.id
      })
        .then(res => {
          if (res.code === 200) {
            this.subForm = res.data.list[0]
            this.$set(this.subForm, 'prizeType', res.data.list[0].prizeType + '')
            this.swiperList = []
            this.swiperList.push({ url: this.subForm.prizeImg })

            // this.swiperList[0].url = this.subForm.prizeImg
          }
        })
        .catch(() => {
          this.showDialog = false
        })
        .finally(() => {
          this.showDialogLoading = false
        })
    },
    handleDeleteItem (row) {
      // if (this.swiperList.length > 0) {
      //   row.prizeImg = this.swiperList[0].url
      // }
      this.$confirm('此操作将删除该商品, 是否继续', '提示')
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          row.status = 0
          saveDailyCheckinPrize(row).then(res => {
            if (res.code === 200) {
              this.queryDataList()
              this.getsetList()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    },
    handleDialogClose () {
      this.subForm = {}
      this.$refs.subForm.resetFields()
    },
    handleConfirm () {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.updateLoading = true
          if (this.dialogTile === '新增商品') {
            this.subForm.prizeImg = this.swiperList[0].url
            saveDailyCheckinPrize(this.subForm)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('新建成功')
                  this.showDialog = false
                  this.queryDataList()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          } else {
            this.subForm.prizeImg = this.swiperList[0].url
            saveDailyCheckinPrize(this.subForm)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('编辑成功')
                  this.showDialog = false
                  this.queryDataList()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          }
        }
      })
    },
    buildDefaultConfig (dayNum) {
      this.contentlist = [] // 先初始化
      for (let i = 0; i < dayNum; i++) {
        this.contentlist.push({ info: { whatDay: i + 1, prizeId: '' }, prize: { prizeImg: '' } })
      }
      return this.contentlist
    }
  }
}
</script>

<style lang="less" scoped>
.game-cover{
  margin-left: 20px;
}
  .page-description {
    font-size: 14px;
    color: #aaa;
    margin: 30px 0;
  }
  .header {
    font-size: 16px;
    padding: 0 0 20px 20px;
  }
  .formcontent{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .div{
      width: 200px;
      height: 200px;
      margin: 20px;
      border: 1px #DCDFE6 solid;
      position: relative;
    }
    .content{
      position: absolute;
      text-align: center;
      bottom: -28px;
      left: 0px;
      width: 100%;
    }
  }
</style>
